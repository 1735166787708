

 

li{

    width:50%;
    list-style-type: none;
   

}

.imageContainer{

    display:flex;

    justify-content:space-between;

    align-items:center;

}

h6{

    text-align:start;

}

.marriedContainerItems-1{

    text-align:start;

}

 

.marriedContainerItems-2{

    text-align:start;

}

 

.householdContainerItems-1{

    text-align:start;

}

 

.householdContainerItems-2{

    text-align:start;

 

}

 

.independentContainerItems-1{

    text-align:start;

}

 

.independentContainerItems-2{

    text-align:start;

}

.ageContainer{

    display:flex;

    flex-direction:column;

    align-items:flex-start;

}

.buttonContainer{

    text-align:start;
    

}
.button{

    border-radius:8px;

    padding:5px;

    background-color:cornflowerblue;

    color:#ffffff;

}

 

 

label{

    text-align:start;

} 