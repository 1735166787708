.ul{
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    padding-left:0px;
    list-style-type:none;
   min-height: 100vh;
}

